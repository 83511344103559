import React from "react";
import { InView } from "react-intersection-observer";
import { graphql, Link } from "gatsby";
import styles from "./index.module.css";

export default function SelectableWorkCards(props) {
  const works = props.cards;

  // dimensions for lazy loading images
  const height = 400;
  const width = 600;

  return (
    <>
      <ul className="grid list-reset grid3col">
        {works.map(entry => {
          const cardImage = entry.workTilePreviewImage.length
            ? entry.workTilePreviewImage
            : entry.heroBackgroundImage;

          return (
            <InView key={entry.id} triggerOnce="true">
              {({ inView, ref }) => (
                <li ref={ref}>
                  <figure className={styles.SelectableWorkCard} aria-label="project card">
                    <Link
                      to={`/${entry.uri}/`}
                      style={{
                        position: "relative",
                        paddingBottom: `${(height / width) * 100}%`,
                        background: "#202c36"
                      }}
                    >
                      {cardImage.length > 0 && (
                        <img
                          src={inView ? cardImage[0].crop : ""}
                          srcSet={inView ? cardImage[0].retina + ' 2x' : ""}
                          className={`${
                            inView ? styles["loaded"] : styles[""]
                          }`}
                          width={width}
                          height={height}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%"
                          }}
                          alt={cardImage[0].altText}
                        />
                      )}

                      <figcaption
                        className={`${styles.SelectableWorkCard__summary} antialiased`}
                      >
                        <div>
                          <h3
                            dangerouslySetInnerHTML={{ __html: entry.title }}
                            className={styles.SelectableWorkCard__summaryTitle}
                          ></h3>

                          {entry.title.category}

                          <p
                            dangerouslySetInnerHTML={{ __html: entry.showName }}
                            className={styles.SelectableWorkCard__summaryData}
                          ></p>
                        </div>
                      </figcaption>
                    </Link>
                  </figure>
                </li>
              )}
            </InView>
          );
        })}
      </ul>
    </>
  );
}

export const selectableWorkCardsData = graphql`
  fragment selectableWorkCardsData on  Craft_modules_selectableWorkCards_BlockType {
    id
    typeHandle
    cards {
      ... on Craft_works_work_Entry {
        id
        postDate
        title
        showName
        category {
          id
          title
          slug
        }
        heroBackgroundImage {
          ... on Craft_uploads_Asset {
            url
            crop: url(width: 600, height: 400, position: "centerCenter")
            retina: url(width: 956, height: 600, position: "centerCenter")
            id
            altText
          }
        }
        workTilePreviewImage {
          ... on Craft_uploads_Asset {
            url
            crop: url(width: 600, height: 400, position: "centerCenter")
            retina: url(width: 956, height: 600, position: "centerCenter")
            id
            altText
          }
        }
        uri
      }
    }
  }
`;
