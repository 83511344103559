import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";

const ImageEl = ({ image }) => (
  <img
    loading="lazy"
    src={image.url}
    alt={image.altText}
    height={image.height}
    width={image.width}
  />
);

export default function Image({
  desktopImage,
  mobileImage,
  url,
  openInNewWindow
}) {
  return (
    <section className="container container--1132px">
      <figure className={mobileImage ? styles.desktopOnly : styles.desktop}>
        {url ? (
          <a
            href={url}
            className={styles.link}
            target={openInNewWindow ? "_blank" : "_self"}
            rel={openInNewWindow ? "noopener noreferrer" : ""}
          >
            <ImageEl image={desktopImage} />
          </a>
        ) : (
          <ImageEl image={desktopImage} />
        )}
      </figure>

      {mobileImage && (
        <figure className={styles.mobile}>
          {url ? (
            <a
              href={url}
              className={styles.link}
              target={openInNewWindow ? "_blank" : "_self"}
              rel={openInNewWindow ? "noopener noreferrer" : ""}
            >
              <ImageEl image={mobileImage} />
            </a>
          ) : (
            <ImageEl image={mobileImage} />
          )}
        </figure>
      )}
    </section>
  );
}

export const imageData = graphql`
  fragment imageData on Craft_modules_image_BlockType {
    typeHandle
    id
    enabled
    href
    openInNewWindow
    desktopImage {
      ... on Craft_uploads_Asset {
        id
        url
        altText
        height
        width
      }
    }
    mobileImage {
      ... on Craft_uploads_Asset {
        id
        url
        altText
        height
        width
      }
    }
  }
`;
