import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";
import HubspotEmbed from "../HubspotEmbed";

export default function twoColumnTextBlock({ textBlock, hubspotForm }) {
  return (
    <section id="text-block" className={styles.TwoColumnTextBlock}>
      <div
        className={`container--538px ${styles.leftColumn}`}
        dangerouslySetInnerHTML={{ __html: textBlock }}
      ></div>
      <div>
      <h2>{hubspotForm[0].formHeading}</h2>
      <HubspotEmbed portalId={hubspotForm[0].portalId} formId={hubspotForm[0].formId}/>
      </div>
    </section>
  );
}

export const twoColumnTextBlockData = graphql`
  fragment twoColumnTextBlockData on Craft_modules_twoColumnTextBlock_BlockType {
    typeHandle
    id
    enabled
    textBlock
    hubspotForm {
      ... on Craft_hubspotForm_BlockType {
        id
        portalId
        formId
        formHeading
      }
    }
  }
`;
