import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function HubspotEmbed({ portalId, formId, mainCssId, overrideCssId, id }) {

  const data = useStaticQuery(graphql`
    {
      craft {
        globalSets(handle: "hubspotStyles") {
          ... on Craft_hubspotStyles_GlobalSet {
            id
            mainCssId
            overrideCssId
          }
        }
      }
    }
  `);

  console.log(data.craft);


  if( typeof document !== 'undefined') {
    const script = document.createElement('script');
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: `${portalId}`,
          formId: `${formId}`,
          target: `#hubspot-embed_${id}`
        });
      }
    });
  }


  return (
    <section>
      <link rel="stylesheet" href={`https://resources.hamilton-exhibits.com/hs-fs/hub/9308072/hub_generated/template_assets/41787265380/${data.craft.globalSets[0].mainCssId}/Hamilton_February_2021/css/main.min.css`} />
      <link rel="stylesheet" href={`https://resources.hamilton-exhibits.com/hs-fs/hub/9308072/hub_generated/template_assets/41787132088/${data.craft.globalSets[0].overrideCssId}/Hamilton_February_2021/css/theme-overrides.min.css`} />
      <div id={'hubspot-embed_' + id}></div>
    </section>
  );
}

export const hubspotEmbedData = graphql`
  fragment hubspotEmbedData on Craft_modules_hubspotEmbed_BlockType {
    typeHandle
    id
    enabled
    portalId
    formId
  }
`;
