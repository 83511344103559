import React from "react";
import TextBlock from "../TextBlock";
import ImageGallery from "../ImageGallery";
import TeamMemberGrid from "../TeamMemberGrid";
import FullwidthImage from "../FullwidthImage";
import ImageGrid from "../ImageGrid";
import BlogCardGrid from "../BlogCardGrid";
import CtaCard from "../CtaCard";
import ImageSlider from "../ImageSlider";
import LogoGrid from "../LogoGrid";
import WorkCard from "../WorkCard";
import RollupCta from "../RollupCta";
import SelectableWorkCards from "../SelectableWorkCards";
import ImageWithContent from "../ImageWithContent";
import VideoPlayer from '../VideoPlayer'
import HubspotEmbed from '../HubspotEmbed'
import TwoColumnTextBlock from "../TwoColumnTextBlock";
import IndustryResourcesGrid from "../IndustryResourcesGrid";
import Timeline from "../Timeline";
import Image from "../Image";

function Spacing(props) {
  const { children, size, top, bottom, showDarkVariant } = props;
  return (
    <div role="presentation" className={showDarkVariant === true ? 'darkStyle' : ''}>
      {!top && <div role="presentation" className={size}></div>}
      {children}
      {!bottom && <div role="presentation" className={size}></div>}
    </div>
  );
}

Spacing.defaultProps = {
  top: false,
  bottom: false,
  size: "spacing-sm"
};

export default function Modules({ modules }) {
  return modules.map((module, index) => {
    const isFirst = index === 0;
    const isLast = index + 1 === modules.length;
    return (
      <Spacing
        key={index}
        bottom={isLast || module.typeHandle === "ctaCard"}
        top={isFirst}
        showDarkVariant={module.showDarkVariant}
      >
        {module.typeHandle === "blogCardGrid" && <BlogCardGrid />}
        {module.typeHandle === "ctaCard" && (
          <CtaCard
            image={module.image}
            overlay={module.overlay}
            ctaHeading={module.ctaHeading}
            ctaText={module.ctaText}
            internalButtonLink={module.internalButtonLink}
            externalButtonLink={module.externalButtonLink}
            buttonText={module.buttonText}
            newTab={module.newTab}
            showDarkVariant={module.showDarkVariant}
          />
        )}
        {module.typeHandle === "fullwidthImage" && (
          <FullwidthImage image={module.image} />
        )}
        {module.typeHandle === "twoColumnTextBlock" && (
          <TwoColumnTextBlock
            textBlock={module.textBlock}
            hubspotForm={module.hubspotForm}
          />
        )}
        {module.typeHandle === "imageGallery" && (
          <ImageGallery
            featuredImage={module.featuredImage}
            images={module.images}
          />
        )}
        {module.typeHandle === "imageGrid" && (
          <ImageGrid showDarkVariant={module.showDarkVariant} images={module.images} />
        )}
        {module.typeHandle === "imageSlider" && (
          <ImageSlider images={module.images} />
        )}
        {module.typeHandle === "logoGrid" && (
          <LogoGrid images={module.images} />
        )}
        {module.typeHandle === "teamMemberGrid" && (
          <TeamMemberGrid
            heading={module.heading}
            introText={module.introText}
          />
        )}
        {module.typeHandle === "textBlock" && (
          <TextBlock showDarkVariant={module.showDarkVariant} richText={module.richText} />
        )}
        {module.typeHandle === "workCard" && <WorkCard />}

        {module.typeHandle === "rollupCta" && (
          <RollupCta rollupCtas={module.rollupCta}/>
        )}

        {module.typeHandle === "selectableWorkCards" && (
          <SelectableWorkCards cards={module.cards}/>
        )}

        {module.typeHandle === "imageWithContent" && (
          <ImageWithContent showDarkVariant={module.showDarkVariant} image={module.image} imagePosition={module.imagePosition} heading={module.heading} text={module.text}/>
        )}
        {module.typeHandle === "video" && (
          <div className="container container--1132px">
            <VideoPlayer showDarkVariant={module.showDarkVariant} src={module.video[0].url} thumbnail={module.thumbnail[0].url} />
          </div>
        )}

        {module.typeHandle === "hubspotEmbed" && (
          <div className="container container--1132px">
            <HubspotEmbed portalId={module.portalId} formId={module.formId} id={module.id} />
          </div>
        )}

        {module.typeHandle === "industryResourcesGrid" &&
          <IndustryResourcesGrid />
        }

        {module.typeHandle === "timeline" &&
          <Timeline header={module.header} body={module.body} events={module.events} />
        }

        {module.typeHandle === "image" &&
          <Image
            desktopImage={module.desktopImage[0]}
            mobileImage={module.mobileImage[0]}
            url={module.href}
            openInNewWindow={module.openInNewWindow}
          />
        }
      </Spacing>
    );
  });
}
