import React, { useState } from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";
import LightBox from "../LightBox";
import useViewportSizes from "use-viewport-sizes";

export default function ImageGallery({ featuredImage, images }) {
  const [vpWidth] = useViewportSizes();
  const [statelightBoxOpen, setlightBoxOpen] = useState(false);
  const [statelightBoxImage, setlightBoxImage] = useState("");
  const allImages = featuredImage.concat(images);
  function unmount() {
    setlightBoxOpen(false);
  }

  function openLightBox(image) {
    if (vpWidth > 768) {
      setlightBoxImage(image);
      setlightBoxOpen(true);
    }
  }

  return (
    <>
      {statelightBoxOpen && (
        <LightBox
          image={statelightBoxImage.url}
          allImages={allImages}
          unmount={unmount}
        />
      )}

      <div
        className={`${styles.ImageGallery} ${"container container--1132px"}`}
      >
        {/* Fullwidth Image Below */}
        {featuredImage[0] && (
          <div className={`${styles["ImageGalleryFullWidth"]}`}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet={encodeURI(featuredImage[0].xLarge)}
                srcSet={` ${featuredImage[0].xLarge + ' 1x'}, ${featuredImage[0].xLarge_2x + ' 2x'} `}
              />
              <source
                media="(min-width: 700px)"
                srcSet={encodeURI(featuredImage[0].large)}
                srcSet={` ${featuredImage[0].large + ' 1x'}, ${featuredImage[0].large_2x + ' 2x'} `}              />
              <source
                media="(min-width: 500px)"
                srcSet={encodeURI(featuredImage[0].medium)}
                srcSet={` ${featuredImage[0].medium + ' 1x'}, ${featuredImage[0].medium_2x + ' 2x'} `}
              />
              <img
                src={featuredImage[0].small}
                srcSet={` ${featuredImage[0].small + ' 1x'}, ${featuredImage[0].small_2x + ' 2x'} `}
                alt={featuredImage[0].altText}
                onClick={() => openLightBox(featuredImage[0])}
              />
            </picture>
          </div>
        )}
        {/* HalfWidth Images below */}
        <div className={`${styles["ImageGalleryHalfWidth"]}`}>
          {images.map((image, index) => (
            <picture key={index}>
              <img
                src={image.thumb}
                srcSet={` ${image.thumb + ' 1x'}, ${image.thumb_2x + ' 2x'} `}
                alt={image.altText}
                onClick={() => openLightBox(image)}
              />
            </picture>
          ))}
        </div>
      </div>
    </>
  );
}

export const imageGalleryData = graphql`
  fragment imageGalleryData on Craft_modules_imageGallery_BlockType {
    typeHandle
    id
    showDarkVariant
    images {
      ... on Craft_uploads_Asset {
        id
        thumb: url(width: 550, height: 300, position: "centerCenter")
        thumb_2x: url(width: 1100, height: 600, position: "centerCenter")
        altText
      }
    }
    featuredImage {
      ... on Craft_uploads_Asset {
        id
        xLarge_2x: url(width: 1600, height: 700, position: "centerCenter")
        large_2x: url(width: 1024, height: 600, position: "centerCenter")
        medium_2x: url(width: 700, height: 400, position: "centerCenter")
        small_2x: url(width: 500, height: 300, position: "centerCenter")
        xLarge: url(width: 3200, height: 1400, position: "centerCenter")
        large: url(width: 2052, height: 1200, position: "centerCenter")
        medium: url(width: 1400, height: 800, position: "centerCenter")
        small: url(width: 1000, height: 600, position: "centerCenter")
        altText
      }
    }
  }
`;
