import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";

export default function ImageWithContent(props) {
  const darkStyle = props.showDarkVariant === true ? styles.ImageWithContent__dark : '';

  return (
    <>
      <div className={[
          styles.ImageWithContent,
          'container container--1132px',
          darkStyle
        ].join(' ')} >
        {props.imagePosition === 'left' &&
          <img src={props.image[0].url} srcSet={props.image[0].retina + ' 2x'} alt={props.image[0].altText}/>
        }
          <div class={styles.ImageWithContent__text}>
            <h2>{props.heading}</h2>
            <span dangerouslySetInnerHTML={{ __html: props.text }}/>
          </div>
        {props.imagePosition === 'right' &&
          <img src={props.image[0].url} srcSet={props.image[0].retina + ' 2x'} alt={props.image[0].altText}/>
        }
      </div>
    </>
  );
}

export const imageWithContentData = graphql`
  fragment imageWithContentData on Craft_modules_imageWithContent_BlockType {
    id
    typeHandle
    heading
    image {
      ... on Craft_uploads_Asset {
        url : url(width: 550, position: "centerCenter")
        retina: url(width: 1100, position: "centerCenter")
        altText
      }
    }
    imagePosition
    text
    showDarkVariant
  }
`;
