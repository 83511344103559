import React, { useState } from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";
import LightBox from "../LightBox";
import useViewportSizes from "use-viewport-sizes";

export default function ImageGrid({ images }) {
  const [vpWidth] = useViewportSizes();
  const [statelightBoxOpen, setlightBoxOpen] = useState(false);
  const [statelightBoxImage, setlightBoxImage] = useState("");

  function unmount() {
    setlightBoxOpen(false);
  }

  function openLightBox(image) {
    if (vpWidth > 768) {
      setlightBoxImage(image);
      setlightBoxOpen(true);
    }
  }

  function colsOrFlexbox() {
    return images.length > 2
      ? `${styles.ImageGrid__masonry} container container--1132px`
      : `${styles.ImageGrid__grid} container container--1132px`;
  }

  return (
    <>
      {statelightBoxOpen && (
        <LightBox
          image={statelightBoxImage.url}
          allImages={images}
          unmount={unmount}
        />
      )}
      <section className={styles.ImageGrid}>
        <div className={colsOrFlexbox()}>
          {images &&
            images.map(image => (
              <img
                key={image.id}
                src={image.thumb}
                srcSet={` ${image.thumb + ' 1x'}, ${image.thumb_2x + ' 2x'} `}
                alt={image.altText}
                onClick={() => openLightBox(image)}
              />
            ))}
        </div>
      </section>
    </>
  );
}

export const imageGridData = graphql`
  fragment imageGridData on Craft_modules_imageGrid_BlockType {
    id
    typeHandle
    showDarkVariant
    images {
      ... on Craft_uploads_Asset {
        id
        url
        thumb: url(width: 600 position: "centerCenter")
        thumb_2x: url(width: 1200 position: "centerCenter")
        altText
      }
    }
  }
`;
