import React from "react";
import { InView } from "react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import styles from "./index.module.css";

export default function IndustryResourcesGrid(props) {

  const data = useStaticQuery(graphql`
    {
      craft {
        entries(type: "industryResources") {
          ... on Craft_industryResources_industryResources_Entry {
            id
            postDate
            title
            shortDescription
            cardUrl
            cardImage {
              ... on Craft_uploads_Asset {
                id
                url
                crop: url(width: 600, height: 400, position: "centerCenter")
                retina: url(width: 956, height: 600, position: "centerCenter")
                altText
              }
            }
          }
        }
      }
    }
  `);



  const resources = data.craft.entries;
  console.log(resources);

  // dimensions for lazy loading images
  const height = 400;
  const width = 600;

  return (
    <>
      <ul className="grid list-reset grid3col">
        {resources.map(entry => {

          return (
            <InView key={entry.id} triggerOnce="true">
              {({ inView, ref }) => (
                <li ref={ref}>
                  <figure className={styles.IndustryResourcesGrid} aria-label="project card">
                    <a
                      href={`${entry.cardUrl}`}
                      style={{
                        background: "#202c36"
                      }}
                    >
                      {entry.cardImage.length > 0 && (
                        <img
                          src={inView ? entry.cardImage[0].crop : ""}
                          srcSet={inView ? entry.cardImage[0].retina + ' 2x' : ""}
                          className={`${
                            inView ? styles["loaded"] : styles[""]
                          }`}
                          width={width}
                          height={height}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%"
                          }}
                          alt={entry.cardImage[0].altText}
                        />
                      )}

                      <figcaption
                        className={`${styles.IndustryResourcesGrid__summary} antialiased`}
                      >
                        <div>
                          <h3
                            dangerouslySetInnerHTML={{ __html: entry.title }}
                            className={styles.IndustryResourcesGrid__summaryTitle}
                          ></h3>
                          <p
                            dangerouslySetInnerHTML={{ __html: entry.shortDescription }}
                            className={styles.IndustryResourcesGrid__summaryData}
                          ></p>
                        </div>
                      </figcaption>
                    </a>
                  </figure>
                </li>
              )}
            </InView>
          );
        })}
      </ul>
    </>
  );
}

export const industryResourcesGridData = graphql`
  fragment industryResourcesGridData on  Craft_modules_industryResourcesGrid_BlockType {
    id
    typeHandle
  }
`;
