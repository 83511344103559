import React, { useRef } from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import { uniqBy } from "lodash";
import { useIntersectionObserver } from "../../helpers/hooks";
import styles from "./index.module.css";

function TimelineItem({ event }) {
  const ref = useRef(null);
  const onScreen = useIntersectionObserver(ref);

  return (
    <div
      ref={ref}
      id={`event-${event.id}`}
      className={clsx(
        styles.timeline_item,
        onScreen && styles.timeline_content_visible
      )}
      key={event.id}
    >
      <div className={styles.timeline_content}>
        <h3>{event.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: event.body }}></div>
      </div>
      <img
        src={event.image[0].thumb}
        alt={event.image[0].title}
        loading="lazy"
        height="270"
        width="400"
        className={styles.timeline_image}
      />
    </div>
  );
}

export default function Timeline({ header, body, events }) {
  const navEvents = uniqBy(events, "title");

  return (
    <>
      <div className={styles.nav}>
        <p className={styles.nav_label}>Jump to date:</p>
        <div className={styles.nav_items}>
          {navEvents.map(event => (
            <button
              key={`event-link-${event.id}`}
              className={styles.nav_button}
              onClick={() => {
                document
                  .querySelector(`#event-${event.id}`)
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              {event.title}
            </button>
          ))}
        </div>
      </div>
      <section className="container container--1132px">
        <h2>{header}</h2>
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        <div className={styles.timeline}>
          {events.map(event => (
            <TimelineItem key={event.id} event={event} />
          ))}
        </div>
      </section>
    </>
  );
}

export const timelineData = graphql`
  fragment timelineData on Craft_modules_timeline_BlockType {
    typeHandle
    id
    enabled
    header
    body
    events {
      ... on Craft_timelineItem_timelineItem_Entry {
        id
        title
        body
        image {
          title
          url
          thumb: url(width: 400)
        }
      }
    }
  }
`;
