import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import RollupCtaCard from "./RollupCtaCard";

export default function RollupCta({rollupCtas}) {
  return (
    <ul className="list-reset grid grid3col">
      {rollupCtas.map((cta, index) => (
        <RollupCtaCard
          key={index}
          copy={cta.copy}
          image={cta.image}
          heading={cta.heading}
        />
      ))}
    </ul>
  );
}

export const rollupCtaData = graphql`
  fragment rollupCtaData on Craft_modules_rollupCta_BlockType {
    id
    typeHandle
    rollupCta {
      ... on Craft_rollupCta_BlockType {
        id
        heading
        copy
        image {
          ... on Craft_uploads_Asset {
            id
            url
            altText
            crop: url(height: 388, width: 479, position: "centerCenter")
            retina: url(height: 776, width: 958, position: "centerCenter")
          }
        }
      }
    }
  }
`;
