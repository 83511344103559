import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.css";
import Next from "../../images/next.svg";
import Previous from "../../images/previous.svg";
import Close from "../../images/close.svg";

export default function LightBox({ iframeUrl, allImages, image, unmount }) {
  const [stateSelectedImage, setImage] = useState(image);

  useEffect(() => {
    document.querySelector("body").classList.add("isStuck");
  }, []);

  useEffect(() => {
    return () => {
      document.querySelector("body").classList.remove("isStuck");
    };
  }, []);

  function close(e) {
    if (e.target === e.currentTarget) {
      unmount();
    }
  }

  function next() {
    const imageIndex = allImages.findIndex(
      ({ url }) => url === stateSelectedImage
    );

    if (imageIndex >= allImages.length - 1) {
      setImage(allImages[0].url);
    } else {
      setImage(allImages[imageIndex + 1].url);
    }
  }

  function previous() {
    const imageIndex = allImages.findIndex(
      ({ url }) => url === stateSelectedImage
    );

    if (imageIndex === 0) {
      setImage(allImages[allImages.length - 1].url);
    } else {
      setImage(allImages[imageIndex - 1].url);
    }
  }

  return ReactDOM.createPortal(
    <section className={styles.LightBox} onClick={e => close(e)}>
      {allImages && (
        <Previous
          className={styles.LightBox__previous}
          onClick={() => previous()}
        />
      )}
      <div className={styles.LightBox__imageWrapper}>
        <span
          onClick={() => unmount()}
          className={`${styles.LightBox__closeButton}`}
        >
          <Close />
        </span>
        {stateSelectedImage && (
          <img
            data-image
            className={styles.LightBox__image}
            alt={stateSelectedImage.altText}
            src={stateSelectedImage}
          />
        )}

        {iframeUrl && (
          <div className={styles.LightBox}>
            <iframe
              title={`Iframe${Math.Random()}`}
              src={iframeUrl}
              frameborder="0"
              width="560"
              height="315"
              vspace="0"
              hspace="0"
              marginheight="5"
              marginwidth="5"
              scrolling="auto"
              allowtransparency="true"
            ></iframe>
          </div>
        )}
      </div>
      {allImages && (
        <Next className={styles.LightBox__next} onClick={() => next()} />
      )}
    </section>,
    document.querySelector("body")
  );
}
